var $ = require('jquery'),
	_ = require('lodash');

require('jquery-sidr');
require('jquery-mousewheel')($);
require('ilightbox');
require('jquery-bridget');
var Masonry = require('masonry-layout');
$.bridget('masonry', Masonry);
//

$(function(){

	var $window = $(window),
		$body = $('body');
	
	$('.header-mobile__toggle').sidr({
		side: 'right'
	});

	$('.js-more').on('click', function(e){
		e.preventDefault();

		var $el = $(this),
			$container = $el.parent().siblings('.js-more-container');

		$el.fadeOut('fast');
		$container.slideDown('fast');
	});

	$('.js-video').iLightBox({
		skin: 'light',
		mousewheel: true,
		path: 'horizontal',
		controls: {
			fullscreen: false
		}
	});

	$('.js-album').on('click', function(e){
		e.preventDefault();

		var $el = $(this),
			p = _.map($el.data('album'), function(m){
				return {
					url: m.url,
					caption: m.title
				};
			});

		$.iLightBox(p, {
			skin: 'light',
			mousewheel: true,
			path: 'horizontal',
			controls: {
				fullscreen: false
			}
		});
	});

	$('form[method="post"]').each(function(index, el){
		var $el = $(el);
		$el.prepend( $('<input/>', {
			type: 'hidden',
			name: 'feedback[robot]',
			value: 'not a robot'
		}) );
	});

	var $grid = $('[data-masonry-options]');
	$window.on('load', function(){
		$grid.masonry().masonry('layout');
	});

	// make video responsive
	$('.rich-text iframe, .rich-text object, .rich-text embed').each(function(index, el){
		var $el = $(el);

		if ($el.data('rich-video-wrapper')){
			return;
		}

		$el.data('rich-video-wrapper', true);

		$el.removeAttr('width').removeAttr('height');

		var $wrapper = $('<div/>', {
			style: 'position: relative;' +
					'padding-bottom: 56.25%; /* 16:9 */' +
					'padding-top: 25px; ' +
					'height: 0;'
		});

		$el.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%'
		});

		$el.wrap( $wrapper );

	});

});

